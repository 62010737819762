<template>
	<div class="indexContent">
		<div class="scrollList">
			<div class="swiper-container gallery-top swiper-container-horizontal">
				<div class="swiper-wrapper">
					<div v-for="value in lbt" class="swiper-slide swiper-slide-next" style="width: 100%; height: 100%; margin-right: 10px;" v-bind:style="{backgroundImage: 'url(' + value.image + ')'}"></div>
				</div>
			</div>
			<div class="swiper-pagination" style="width: 93%;text-align: right;margin-right: 15px;bottom: 20px;position: relative;"></div>
		</div>
		<div style="padding: 10px 0 0 0; position: relative;" v-show="typeid!=3">

			<img src="../../../public/static/images/bk.png" style="width: 100%;" />
			<div style="position: absolute; width: 100%; height: 100% ;top:0;">
				<div style="position: absolute; width: 35%; height: 100%; left: 0; " @click="GoList(1)"></div>
				<div style="position: absolute; width: 63%; height: 40%; right: 0; top: 4%;" @click="GoList(2)"></div>
				<div style="position: absolute; width: 21%; height: 50%; left:35%; bottom: 5%;" @click="GoList(3)"></div>
				<div style="position: absolute; width: 21%; height: 50%; left:56%; bottom: 5%;" @click="GoList(4)"></div>
				<div style="position: absolute; width: 21%; height: 50%; left:77%; bottom: 5%;" @click="GoList(5)"></div>
			</div>
		</div>
		<div style="padding:6px 0 0 6px;" v-show="d1.length>0">
			<div class="hots flexleft"><span></span>{{typeid==3?'直播列表':'肠胃调理'}}</div>
		</div>
		<div class="community">
			<div class="itemList" v-for="item of d1" @click="ViewArticle(item)">
				<div class="itemImg">
					<img :src="item.image" />
					<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
				</div>
				<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
				<div class="itemName">{{item.title}}</div>
				<div class="itemVolume">{{item.summary}}</div>
				<div class="imgs" v-if="!item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
			</div>

			
		</div>
		
		
		<div style="padding:6px 0 0 6px; margin-top: 10px;" v-show="d2.length>0">
			<div class="hots flexleft"><span></span>婴儿皮肤管理</div>
		</div>
		<div class="community">
			<div class="itemList" v-for="item of d2" @click="ViewArticle(item)">
				<div class="itemImg">
					<img :src="item.image" />
					<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
				</div>
				<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
				<div class="itemName">{{item.title}}</div>
				<div class="itemVolume">{{item.summary}}</div>
				<div class="imgs" v-if="!item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
			</div>

			
		</div>
		
		
		<div style="padding:6px 0 0 6px; margin-top: 10px" v-show="d3.length>0">
			<div class="hots flexleft"><span></span>特殊配方</div>
		</div>
		<div class="community">
			<div class="itemList" v-for="item of d3" @click="ViewArticle(item)">
				<div class="itemImg">
					<img :src="item.image" />
					<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
				</div>
				<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
				<div class="itemName">{{item.title}}</div>
				<div class="itemVolume">{{item.summary}}</div>
				<div class="imgs" v-if="!item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
			</div>

			
		</div>
		<div style="padding:6px 0 0 6px; margin-top: 10px" v-show="d4.length>0">
			<div class="hots flexleft"><span></span>基础护理</div>
		</div>
		<div class="community">
			<div class="itemList" v-for="item of d4" @click="ViewArticle(item)">
				<div class="itemImg">
					<img :src="item.image" />
					<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
				</div>
				<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
				<div class="itemName">{{item.title}}</div>
				<div class="itemVolume">{{item.summary}}</div>
				<div class="imgs" v-if="!item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
			</div>

			
		</div>
		<div style="padding:6px 0 0 6px; margin-top: 10px" v-show="d5.length>0">
			<div class="hots flexleft"><span></span>生物抗菌</div>
		</div>
		<div class="community">
			<div class="itemList" v-for="item of d5" @click="ViewArticle(item)">
				<div class="itemImg">
					<img :src="item.image" />
					<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
				</div>
				<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
				<div class="itemName">{{item.title}}</div>
				<div class="itemVolume">{{item.summary}}</div>
				<div class="imgs" v-if="!item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
			</div>

			
		</div>
		<div style="height: 50px;"></div>

	</div>
</template>

<script>
	import Swiper from '../../../public/static/swiper.min.js'
	let galleryTop
	let galleryThumbs
	export default {
		data() {
			return {
				lbt: [],
				hotList: [], //热销列表
				typeid:0,
				d1: [],
				d2: [],
				d3: [],
				d4: [],
				d5: []
			}
		},
		created() {
			this.typeid = this.$route.params.id;
			if(this.typeid==1){
				document.title="微课堂";
			}else if(this.typeid==2){
				document.title="咨询";
			}else if(this.typeid==3){
				document.title="直播";
			}
			this.GetLunboList(); //轮播图
			this.GetNrList();

		},
		mounted() {
			document.title = "营养中心";
		},
		methods: {
			lunbo() {
				let that = this;
				galleryTop = new Swiper('.gallery-top', {
					// 如果需要分页器
					pagination: '.swiper-pagination',
					autoplay: 3000,
					speed: 300,
					spaceBetween: 10,
					grabCursor: true,
					initialSlide: 1,
					autoplayDisableOnInteraction: false,
					onClick: function(swiper) {
						for(var i = 0; i < that.lbt.length; i++) {
							if(i == swiper.activeIndex) {
								if(that.lbt[i].outhref != "" && that.lbt[i].outhref.indexOf("http") >= 0) {
									window.location.href = that.lbt[i].outhref
								} else {
									console.log("没有外链")
								}
							}
						}

					}
				})
				galleryThumbs = new Swiper('.swiper-pagination', {
					spaceBetween: 10,
					autoplay: 4000,
					initialSlide: 1,
					centeredSlides: true,
					slidesPerView: 'auto',
					touchRatio: 0.2,
					slideToClickedSlide: true,
					autoplayDisableOnInteraction: false,
					grabCursor: true
				})
				galleryTop.params.control = galleryThumbs
				galleryThumbs.params.control = galleryTop
			},
			//轮播图
			GetLunboList() {
				var self = this
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=carousel"
				}).then(result => {
					self.lbt = result.data.data.rows.rows;
					this.$nextTick(() => {
						this.lunbo();
					})

				})
			},
			GetNrList() {
				var self = this
				this.$store.dispatch("GET", {
					url: "/api/article/?action=sublist&type="+this.$route.params.id
				}).then(result => {
					if(result.data.d1){
						this.d1 = result.data.d1
					}
					if(result.data.d2){
						this.d2 = result.data.d2
					}
					if(result.data.d3){
						this.d3 = result.data.d3
					}
					if(result.data.d4){
						this.d4 = result.data.d4
					}
					if(result.data.d5){
						this.d5 = result.data.d5
					}
				})
			},
			ViewArticle(item) {
				if(item.link.indexOf("http")>=0){
					window.location.href=item.link;
				}else{
					if(isNaN(item.link)){//商品id
						this.$router.push("/prodetail/" + item.link)
					}else{
						this.$router.push("/article/" + item.id)
					}
				}
				
			},
			GoList(id){
				this.$router.push("/community/"+this.typeid+"_" + id)
			}

		}
	}
</script>

<style @scoped>
	@import url("../../common/css/swiper.min.css");
	.gallery-top {
		height: 50vw;
		width: 100%;
	}
	
	.gallery-thumbs {
		height: 20%;
		box-sizing: border-box;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0.3;
		cursor: pointer;
	}
	
	.gallery-thumbs .swiper-slide {
		width: 30%;
		opacity: 0.3;
	}
	
	.gallery-thumbs .swiper-slide-active {
		opacity: 1;
	}
	
	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		display: inline-block;
		border-radius: 100%;
		border: 1px solid #656565;
		margin-left: 10px;
	}
	
	.swiper-slide {
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
	}
	
	.header {
		width: 100%;
		height: 54px;
	}
	
	.scrollList {
		width: 100%;
		height: 50vw;
	}
	
	.proType {
		width: 100%;
		height: 47.5vw;
		display: flex;
		margin-top: 10px;
	}
	
	.proTypeLeft {
		margin-left: 10px;
		width: 33%;
		height: 166px;
		background: #FFFFFF;
		border-radius: 10px;
	}
	
	.proTypeRight {
		flex: 1;
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.proList {
		height: 500px;
		/*border: 1px solid red;*/
		margin-bottom: 60px;
	}
	
	.hotTitle {
		height: 36px;
		line-height: 36px;
		display: flex;
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.hots {
		flex: 1;
		font-size: 16px;
		color: #5586d2;
	}
	
	.hots span {
		background: #5586d2;
		width: 6px;
		height: 26px;
		display: inline-block;
		margin-right: 5px;
	}
	
	.more {
		flex: 1;
		text-align: right;
		font-size: 12px;
		color: #a3a3a3;
	}
	
	.hotList {
		margin-top: 10px;
		padding-left: 2.6%;
	}
	
	.hotlist_left {
		float: left;
		border: 1px solid #bde2ff;
		width: 47.5%;
		margin-right: 1.5%;
		height: 57vw;
		background: #FFFFFF;
		margin-bottom: 10px;
		position: relative;
	}
	
	.hotimg {
		text-align: center;
		padding-top: 20px;
	}
	
	.hotimg img {
		width: 40vw;
		height: 40vw;
	}
	
	.hotlist_middle {
		float: left;
		width: 2.5%;
	}
	
	.proname {
		text-align: center;
		font-size: 13px;
		color: #7b7b7b;
		padding: 0px 5%;
		height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 90%;
		line-height: 20px;
	}
	
	.clear {
		clear: both;
	}
	
	.community {
		height: 100%;
		width: 100%;
	}
	
	.itemList {
		background: #FFFFFF;
		border-radius: 5px;
		width: 96%;
		margin: 0 auto;
		margin-top: 20px;
		padding-bottom: 10px;
	}
	
	.itemImg {
		width: 100%;
		height: 54vw;
		position: relative;
	}
	
	.headimg {
		position: absolute;
		width: 20vw;
		height: 20vw;
		left: 20px;
		bottom: -10vw;
		border-radius: 50%;
	}
	
	.itemImg img:nth-child(1) {
		height: 100%;
		width: 100%;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	
	.itemName {
		font-size: 16px;
		color: #474747;
		padding: 12px;
	}
	
	.itemVolume {
		font-size: 12px;
		color: #9a9a9a;
		padding: 5px 12px;
	}
	
	.imgs {
		padding: 5px 12px 15px 12px;
		text-align: right;
	}
</style>